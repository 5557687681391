import qs from "qs";
import reposWebApi, {reposNodeApi, version} from "./configuration";
import keys from "../keys";
import secureLocalStorage from "react-secure-storage";
import fernet from "fernet";
import decryption from "../encryption/Decryption";
import {invertArrToJson} from "../constants/constants";

// Read Operations

let secret = new fernet.Secret("K_5H0OzfMLk_FIcGQkg_H5W3WHNBRQWMoAmKCuEhNeY=");
let token = new fernet.Token({
    secret: secret
});
token.encode("string");

const encryptedData = response => {
    let secret = new fernet.Secret(
        "BbSePhsFh4eJ5U5hUkfTm_Y1R1wS4PhWnLltHUPiMW8="
    );
    let token = new fernet.Token({
        secret: secret
    });
    token.encode("string");

    let toke = new fernet.Token({
        secret: secret,
        token: response?.data,
        ttl: 0
    });

    return JSON.parse(toke.decode());
};

export const loginRequest = (
    userData,
    history,
    enqueueSnackbar,
    setIsLoading
) => {
    const requestBody = {
        mobile: userData.userName,
        password: userData.password,
        is_email: "0",
        grant_type: "password",
        client_secret: keys.client_secret,
        client_id: keys.client_id
    };

    const config = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        }
    };

    return dispatch => {
        reposWebApi
            .post("/superadmin_login", qs.stringify(requestBody), config)
            .then(async response => {
                let toke = new fernet.Token({
                    secret: secret,
                    token: response?.data?.response_data,
                    ttl: 0
                });

                console.log(JSON.parse(toke.decode()));

                dispatch({
                    type: "LOGIN_SUCCESS",
                    payload: response
                });

                secureLocalStorage.setItem(
                    "department",
                    JSON.parse(toke.decode())?.user_details?.department
                );
                secureLocalStorage.setItem(
                    "access_type",
                    JSON.parse(toke.decode())?.user_details?.access_type
                );
                secureLocalStorage.setItem(
                    "mobile",
                    JSON.parse(toke.decode())?.user_details?.mobile
                );
                secureLocalStorage.setItem(
                    "is_crm",
                    JSON.parse(toke.decode())?.user_details?.is_crm
                );
                secureLocalStorage.setItem(
                    "crm_rank",
                    JSON.parse(toke.decode())?.user_details?.crm_rank
                );
                secureLocalStorage.setItem(
                    "id",
                    JSON.parse(toke.decode())?.user_details?.id
                );

                if (JSON.parse(toke.decode())?.user_details?.department === "9") {
                    await history.push("/service_request");
                    setIsLoading(false);
                } else if (
                    JSON.parse(toke.decode())?.user_details?.department === "11"
                ) {
                    await history.push("/ReposCRM");
                    setIsLoading(false);
                } else {
                    await history.push("/welcome");
                    setIsLoading(false);
                }
            })
            .catch(error => {
                setIsLoading(false);
                if (error.response?.status === 400) {
                    dispatch({
                        type: "LOGIN_ERROR",
                        payload: true
                    });
                } else if (error.response?.status === 406) {
                    dispatch({
                        type: "INVALID_LOGIN",
                        payload: true
                    });
                    enqueueSnackbar("Invalid user id or password login", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right"
                        }
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const logoutRequest = history => {
    return dispatch => {
        dispatch({
            type: "LOG_OUT",
            payload: "logout"
        });
        // secureLocalStorage.setItem('isAuthenticated', false);
        // secureLocalStorage.removeItem('accessToken');
        // secureLocalStorage.removeItem('userDetails');
        // secureLocalStorage.removeItem('refreshToken');
        secureLocalStorage.clear();
        history?.push("/sign-in");
    };
};

export const getAllCustomer = (
    accessToken,
    searchTerm,
    limit,
    offset,
    fromDate,
    toDate,
    abortController
) => {
    let type = "GET_ALL_CUSTOMER";
    let queryParams = {};

    if (searchTerm !== "") {
        type = "GET_ALL_CUSTOMER_SEARCH";
        queryParams.search = searchTerm;
    } else {
        type = "GET_ALL_CUSTOMER";
    }

    if (limit !== null && offset !== null) {
        type = "GET_ALL_CUSTOMER_BY_LIMIT";
        queryParams.limit = limit;
        queryParams.offset = offset;
    }

    // eslint-disable-next-line
    if (
        fromDate !== null ||
        (fromDate !== "" && toDate !== null) ||
        toDate !== ""
    ) {
        type = "GET_ALL_CUSTOMER_BY_DATE";
        queryParams.start_date = fromDate;
        queryParams.end_date = toDate;
    }

    return dispatch => {
        dispatch({
            type: "GET_ALL_CUSTOMERS_LOADING",
            payload: true
        });
        reposWebApi
            .get("/customer/all/?fis_customer=0", {
                cancelToken: abortController.token,
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params: queryParams
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: type,
                        payload: encryptedData(response)?.data
                        // payload:response.data.data
                    });
                }
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getAllDrivers = (
    accessToken,
    searchTerm,
    limit,
    offset,
    abortController
) => {
    let type = "GET_ALL_DRIVERS";

    let queryParams = {};

    if (searchTerm !== "") {
        type = "GET_ALL_DRIVERS_SEARCH";
        queryParams.search = searchTerm;
    } else {
        type = "GET_ALL_DRIVERS";
    }

    if (limit !== null && offset !== null) {
        type = "GET_ALL_DRIVERS_BY_LIMIT";
        queryParams.limit = limit;
        queryParams.offset = offset;
    }

    return dispatch => {
        dispatch({
            type: "GET_ALL_DRIVERS_LOADING",
            payload: true
        });
        reposWebApi
            .get("/partner/driver/list/", {
                cancelToken: abortController.token,
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params: queryParams
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: type,
                        payload: encryptedData(response)?.data
                    });
                }
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getAllRefuellers = (
    accessToken,
    searchTerm,
    limit,
    offset,
    partner,
    abortController
) => {
    let type = "GET_ALL_REFUELLERS";

    let queryParams = {};

    if (searchTerm !== "") {
        type = "GET_ALL_REFUELLERS_SEARCH";
        queryParams.search = searchTerm;
    } else {
        type = "GET_ALL_REFUELLERS";
    }

    if (searchTerm !== "") {
        type = "GET_ALL_REFUELLERS_PARTNER";
        queryParams.partner = partner;
    } else {
        type = "GET_ALL_REFUELLERS";
    }

    if (limit !== null && offset !== null) {
        type = "GET_ALL_REFUELLERS_BY_LIMIT";
        queryParams.limit = limit;
        queryParams.offset = offset;
    }

    return dispatch => {
        dispatch({
            type: "GET_ALL_REFUELERS_LOADING",
            payload: true
        });
        reposWebApi
            .get("/refueler/list", {
                cancelToken: abortController.token,
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params: queryParams
            })
            .then(response => {
                dispatch({
                    type: type,
                    payload: {
                        refuellerData: encryptedData(response)?.data
                    }
                });
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getAllPartners = (
    accessToken,
    searchTerm,
    limit,
    offset,
    refueller,
    city,
    abortController,
    fis_orders,
    pinCode
) => {
    // console.log(pinCode, searchTerm);

    let type = "GET_ALL_PARTNERS";

    let queryParams = {};

    if (searchTerm !== "") {
        type = "GET_ALL_PARTNERS_SEARCH";
        queryParams.search = searchTerm;
        queryParams.fis_orders = fis_orders;
    }

    if (pinCode !== "") {
        type = "GET_ALL_PARTNERS_SEARCH";
        queryParams.pin_code = pinCode;
        queryParams.fis_orders = fis_orders;
    }

    if (limit !== null && offset !== null) {
        type = "GET_ALL_PARTNERS_BY_LIMIT";
        queryParams.limit = limit;
        queryParams.offset = offset;
        queryParams.fis_orders = fis_orders;
    }

    return dispatch => {
        dispatch({
            type: "GET_ALL_PARTNERS_LOADING",
            payload: true
        });
        reposWebApi
            .get("/partner/all/", {
                cancelToken: abortController.token,
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params: queryParams
            })
            .then(partners => {
                if (partners?.status === 200) {
                    dispatch({
                        type: type,
                        payload: {
                            partnersData: encryptedData(partners),
                            refuellerData: [],
                            cityData: []
                        }
                    });
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getAllOrders = (
    accessToken,
    searchTerm,
    limit,
    offset,
    status,
    fromDate,
    toDate,
    is_finserv_enabled,
    self_pickup,
    abortController,
    props
) => {
    let type = "GET_ALL_ORDERS";

    let queryParams = {};

    if (searchTerm !== "") {
        type = "GET_ALL_ORDERS_SEARCH";
        queryParams.search = searchTerm;
        queryParams.limit = limit;
        queryParams.offset = offset;
    }

    if (status !== "") {
        type = "GET_ALL_ORDERS_BY_STATUS";
        queryParams.status = status;
        queryParams.limit = limit;
        queryParams.offset = offset;
    }

    if (is_finserv_enabled !== "") {
        type = "GET_ALL_ORDERS_BY_ORDERTYPE";
        queryParams.status = status;
        queryParams.limit = limit;
        queryParams.offset = offset;
        if (is_finserv_enabled === 2 || is_finserv_enabled === 3) {
            queryParams.self_pickup = is_finserv_enabled === 2 ? 0 : 1;
        } else {
            queryParams.is_finserv_enabled = is_finserv_enabled;
        }
    }

    // if(self_pickup !== ''){
    //     type = 'GET_ALL_ORDERS_BY_DELIVERYMODE';
    //     queryParams.status = status;
    //     queryParams.limit = limit;
    //     queryParams.offset = offset;
    //     //queryParams.is_finserv_enabled = is_finserv_enabled;
    //     queryParams.self_pickup = self_pickup;
    // }

    if (limit !== null && offset !== null) {
        type = "GET_ALL_ORDERS_BY_LIMIT";
        queryParams.status = status;
        queryParams.limit = limit;
        queryParams.offset = offset;
    }

    // eslint-disable-next-line
    if (
        fromDate !== null ||
        (fromDate !== "" && toDate !== null) ||
        toDate !== ""
    ) {
        type = "GET_ALL_ORDERS_BY_DATE";
        queryParams.start_date = fromDate;
        queryParams.end_date = toDate;
    }

    return dispatch => {
        dispatch({
            type: "GET_ALL_ORDERS_LOADING",
            payload: true
        });
        reposWebApi
            .get("/order/get_orders", {
                cancelToken: abortController.token,
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params: queryParams
            })
            .then(response => {
                if (response?.status === 200) {
                    let data = encryptedData(response)?.data?.results.filter(
                        customers =>
                            customers.customer_name
                                ?.toLowerCase()
                                .includes(searchTerm.toLowerCase()) ||
                            customers.order_id
                                ?.toLowerCase()
                                .includes(searchTerm.toLowerCase())
                    );
                    if (searchTerm.length === 0) {
                        dispatch({
                            type: type,
                            payload: {
                                ordersData: encryptedData(response)
                            }
                        });
                    } else {
                        if (data.length !== 0) {
                            dispatch({
                                type: type,
                                payload: {
                                    ordersData: encryptedData(response)
                                }
                            });
                        } else {
                            dispatch({
                                type: type,
                                payload: {
                                    ordersData: encryptedData(response)
                                }
                            });
                        }
                    }
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    props.enqueueSnackbar("Something went wrong!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR_FOR_ORDER",
                        payload: true
                    });
                }
            });
    };
};

export const getAllMarkers = (accessToken, filterValue) => {
    let type = "GET_ALL_MARKERS";

    let queryParams = {
        fis_orders: 0
    };

    queryParams.limit = 500;

    switch (filterValue) {
        case 1:
            // code block
            return dispatch => {
                dispatch({
                    type: "ALL_MARKERS_CLEAR"
                });
                reposWebApi
                    .get("/partner/all", {
                        headers: {
                            Authorization: "Bearer " + accessToken
                        },
                        params: queryParams
                    })
                    .then(partners => {
                        let arr = encryptedData(partners)?.data?.results.map(e => {
                            return {markerdata: e.pumps[0]?.pump_lat_lon, name: e.name};
                        });

                        if (partners.status === 200) {
                            dispatch({
                                type: type,
                                payload: arr
                            });
                        }
                    })
                    .catch(error => {
                        if (error?.response?.status === 401) {
                            dispatch({
                                type: "SESSION_EXPIRED",
                                payload: true
                            });
                        }
                        if (error?.response?.status === 500) {
                            dispatch({
                                type: "INTERNAL_SERVER_ERROR",
                                payload: true
                            });
                        }
                    });
            };
        // break;
        case 2:
            // code block
            return dispatch => {
                dispatch({
                    type: "ALL_MARKERS_CLEAR"
                });
                reposWebApi
                    .get("/customer/asset/all/", {
                        headers: {
                            Authorization: "Bearer " + accessToken
                        }
                        // params: queryParams
                    })
                    .then(response => {
                        let arr = [];
                        for (
                            let i = 0;
                            i < encryptedData(response)?.data?.results?.length;
                            i++
                        ) {
                            if (
                                encryptedData(response)?.data?.results[i].asset_lat_lon !== null
                            ) {
                                arr.push({
                                    markerdata: encryptedData(response)?.data?.results[i]
                                        ?.asset_lat_lon,
                                    name: encryptedData(response)?.data?.results[i]?.name
                                });
                            }
                        }
                        if (response?.status === 200) {
                            dispatch({
                                type: type,
                                payload: arr
                            });
                        }
                    })
                    .catch(error => {
                        if (error?.response?.status === 401) {
                            dispatch({
                                type: "SESSION_EXPIRED",
                                payload: true
                            });
                        }
                        if (error?.response?.status === 500) {
                            dispatch({
                                type: "INTERNAL_SERVER_ERROR",
                                payload: true
                            });
                        }
                    });
            };
        case 3:
            return dispatch => {
                dispatch({
                    type: "ALL_MARKERS_CLEAR"
                });
                reposWebApi
                    .get("/partner/list_all_growth_partner", {
                        headers: {
                            Authorization: "Bearer " + accessToken
                        },
                        params: {
                            limit: 500
                        }
                    })
                    .then(partners => {
                        let arr = encryptedData(partners)?.data?.results.map(e => {
                            return {
                                markerdata: e.pumps[0]?.pump_lat_lon,
                                name: e.pumps[0].pump_name
                            };
                        });
                        if (partners.status === 200) {
                            dispatch({
                                type: type,
                                payload: arr
                            });
                        }
                    })
                    .catch(error => {
                        if (error?.response?.status === 401) {
                            dispatch({
                                type: "SESSION_EXPIRED",
                                paylaod: true
                            });
                        }
                        if (error?.response?.status === 500) {
                            dispatch({
                                type: "INTERNAL_SERVER_ERROR",
                                payload: true
                            });
                        }
                    });
            };
        // break;
        default:
            // code block
            return dispatch => {
                dispatch({
                    type: "ALL_MARKERS_CLEAR"
                });
                reposWebApi
                    .get("/refueler/refueler_live_tracking", {
                        headers: {
                            Authorization: "Bearer " + accessToken
                        }
                        // params: queryParams
                    })
                    .then(response => {
                        let arr = encryptedData(response)?.data?.map(e => {
                            return {
                                markerdata: e.last_latlon,
                                name: e.vehicle_registration_number
                            };
                        });
                        if (response?.status === 200) {
                            dispatch({
                                type: type,
                                payload: arr
                            });
                        }
                    })
                    .catch(error => {
                        if (error?.response?.status === 401) {
                            dispatch({
                                type: "SESSION_EXPIRED",
                                payload: true
                            });
                        }
                        if (error?.response?.status === 500) {
                            dispatch({
                                type: "INTERNAL_SERVER_ERROR",
                                payload: true
                            });
                        }
                    });
            };
    }
};

export const getAllUsers = (accessToken, limit, offset) => {
    let type = "GET_ALL_USERS";

    let queryParams = {};

    if (limit !== null && offset !== null) {
        type = "GET_ALL_USERS";
        queryParams.limit = limit;
        queryParams.offset = offset;
    }

    return dispatch => {
        dispatch({
            type: "GET_ALL_USERS_LOADING",
            payload: true
        });
        reposWebApi
            .get("/customer/superadminusers/list/register", {
                //cancelToken:abortController.token,
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params: queryParams
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: type,
                        payload: encryptedData(response)?.data
                    });
                }
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const editUser = (accessToken, userForm, props) => {
    let requestBody = {};
    requestBody = {
        department: userForm.department,
        zone: userForm.zoneSelected,
        region: userForm.regionSelected,
        access_type: userForm.accessTypeSelected,
        mobile: userForm.mobile,
        state_list: userForm.state_list,
        name: userForm.name,
        email: userForm.email,
        is_crm: userForm.crmToggled,
        state: userForm.state_area,
        territory: userForm.territory,
        rank: userForm.rank,
        reporting_to: userForm.reporting_to,
        repos_id: userForm.repos_id
    };

    return dispatch => {
        dispatch({
            type: "EDIT_USER",
            payload: true
        });

        reposWebApi
            .put(
                "/customer/superadminuser/update/" + userForm.id,
                qs.stringify(requestBody),
                {
                    headers: {
                        Authorization: "Bearer " + accessToken
                    }
                }
            )
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "EDIT_USER_SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    props.enqueueSnackbar("User data updated Successfully", {
                        variant: "Success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    props.drawerClose();
                    dispatch(getAllUsers(accessToken));
                }
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    props.enqueueSnackbar("Bad Request..!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "EDIT_USER_FAIL",
                        payload: false
                    });
                }
                if (error.response?.status === 500) {
                    props.enqueueSnackbar("Internal Server Error..!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                }
                if (error.response?.status === 401) {
                    props.enqueueSnackbar("Session Expired", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
            });
    };
};

export const getAllGeofences = (accessToken, searchTerm, limit, offset) => {
    let type = "GET_ALL_GEOFENCES";

    let queryParams = {};

    if (searchTerm !== "") {
        type = "GET_ALL_GEOFENCES_SEARCH";
        queryParams.search = searchTerm;
    } else {
        type = "GET_ALL_GEOFENCES";
    }

    // if (customer !== null){
    //     type = 'GET_ALL_GEOFENCES_BY_CUSTOMER';
    //     queryParams.customer_id = customer
    // }
    // else {
    //     type = "GET_ALL_GEOFENCES";
    // }

    if (limit !== null && offset !== null) {
        type = "GET_ALL_GEOFENCES_BY_LIMIT";
        queryParams.limit = limit;
        queryParams.offset = offset;
    }

    return dispatch => {
        dispatch({
            type: "GET_ALL_GEOFENCES_LOADING",
            payload: true
        });
        reposWebApi
            .get("/customer/list_all_geofence", {
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params: queryParams
            })
            .then(geofence => {
                reposWebApi
                    .get("/customer/all/", {
                        headers: {
                            Authorization: "Bearer " + accessToken
                        }
                    })
                    .then(customer => {
                        if (geofence?.status === 200) {
                            dispatch({
                                type: type,
                                payload: {
                                    allGeofencesData: geofence?.data,
                                    allCustomerData: customer?.data?.data?.results
                                }
                            });
                        }
                    })
                    .catch(error => {
                        if (error?.response?.status === 401) {
                            dispatch({
                                type: "SESSION_EXPIRED",
                                payload: true
                            });
                        }
                        if (error?.response?.status === 500) {
                            dispatch({
                                type: "INTERNAL_SERVER_ERROR",
                                payload: true
                            });
                        }
                    });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getAllAssets = (
    accessToken,
    searchTerm,
    limit,
    offset,
    customer,
    asset,
    abortController
) => {
    let type = "GET_ALL_ASSETS";

    let queryParams = {};

    if (searchTerm !== "") {
        type = "GET_ALL_ASSETS_SEARCH";
        queryParams.search = searchTerm;
    } else {
        type = "GET_ALL_ASSETS";
    }
    // Staging
    // if (asset !== '') {
    //     if (asset === '0') {
    //         type = "GET_ALL_ASSETS";
    //     } else {
    //         type = 'GET_ALL_ASSETS_TYPE';
    //         queryParams.asset_type = asset;
    //     }
    // }
    // Live
    if (asset !== "") {
        if (asset === "0") {
            type = "GET_ALL_ASSETS";
            queryParams.all_asset = 1;
        } else {
            type = "GET_ALL_ASSETS_TYPE";
            queryParams.asset_type = asset;
        }
    }

    if (limit !== null && offset !== null) {
        type = "GET_ALL_ASSETS_BY_LIMIT";
        queryParams.limit = limit;
        queryParams.offset = offset;
    }

    if (customer !== null) {
        type = "GET_ALL_ASSETS_BY_CUSTOMER";
        queryParams.customer = customer;
    }

    return dispatch => {
        dispatch({
            type: "GET_ALL_ASSETS_LOADING",
            payload: true
        });
        reposWebApi
            .get("/customer/asset/all/", {
                cancelToken: abortController.token,
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params: queryParams
            })
            // .then(assets => {
            //       reposWebApi.get('/customer/all?fis_customer=0', {
            //           cancelToken:abortController.token,
            //           headers: {
            //               Authorization: 'Bearer ' + accessToken
            //           },
            //       })
            .then(assets => {
                if (assets?.status === 200) {
                    dispatch({
                        type: type,
                        payload: {
                            allAssetsData: encryptedData(assets)
                            // allCustomerData: customer?.data?.data?.results
                        }
                    });
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            })
            // })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getAllTrackers = (
    accessToken,
    searchTerm,
    limit,
    offset,
    refueller,
    abortController
) => {
    let type = "GET_ALL_TRACKERS";

    let queryParams = {};

    if (searchTerm !== "") {
        type = "GET_ALL_TRACKERS_SEARCH";
        queryParams.search = searchTerm;
    } else {
        type = "GET_ALL_TRACKERS";
    }

    if (refueller !== null) {
        type = "GET_ALL_TRACKERS_BY_REFUELLERS";
        queryParams.refueler = refueller;
    }

    if (limit !== null && offset !== null) {
        type = "GET_ALL_TRACKERS_BY_LIMIT";
        queryParams.limit = limit;
        queryParams.offset = offset;
    }

    return dispatch => {
        dispatch({
            type: "GET_ALL_TRACKERS_LOADING",
            payload: true
        });
        reposWebApi
            .get("/tracker/all/", {
                cancelToken: abortController.token,
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params: queryParams
            })
            .then(response => {
                reposWebApi
                    .get("/refueler/list", {
                        cancelToken: abortController.token,
                        headers: {
                            Authorization: "Bearer " + accessToken
                        }
                        // params: queryParams
                    })
                    .then(res => {
                        if (response?.status === 200) {
                            dispatch({
                                type: type,
                                payload: {
                                    refuellerData: encryptedData(res).data,
                                    trackerData: encryptedData(response)?.data
                                }
                            });
                        }
                    })
                    .catch(error => {
                        if (error?.response?.status === 401) {
                            dispatch({
                                type: "SESSION_EXPIRED",
                                payload: true
                            });
                        }
                        if (error?.response?.status === 500) {
                            dispatch({
                                type: "INTERNAL_SERVER_ERROR",
                                payload: true
                            });
                        }
                    });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getAllDashboardData = accessToken => {
    return dispatch => {
        dispatch({
            type: "GET_DASHBOARD_DATA_LOADING",
            payload: true
        });
        reposWebApi
            .get("/utilities/lmd-dashboard", {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                reposWebApi
                    .get("/tracker/lmd-dashboard-dispense", {
                        headers: {
                            Authorization: "Bearer " + accessToken
                        }
                    })
                    .then(directModeData => {
                        dispatch({
                            type: "GET_DASHBOARD_DATA",
                            payload: {
                                utilitiesData: encryptedData(response)?.data,
                                directModeData: encryptedData(directModeData)?.data
                            }
                        });
                    });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getAllRefuellersAndOrders = (
    accessToken,
    searchTerm,
    limit,
    offset,
    partner,
    abortController
) => {
    let type = "GET_ALL_REFUELLERS_AND_ORDERS";

    let queryParams = {};

    if (searchTerm !== "") {
        type = "GET_ALL_REFUELLERS_AND_ORDERS_SEARCH";
        queryParams.search = searchTerm;
    } else {
        type = "GET_ALL_REFUELLERS_AND_ORDERS";
    }

    if (limit !== null && offset !== null) {
        type = "GET_ALL_REFUELLERS_AND_ORDERS_BY_LIMIT";
        queryParams.limit = limit;
        queryParams.offset = offset;
        queryParams.partner = partner;
    }
    return dispatch => {
        dispatch({
            type: "GET_ALL_REFUELLERS_AND_ORDERS_LOADING",
            payload: true
        });
        reposWebApi
            .get("/refueler/refuelers_for_order_assignment", {
                cancelToken: abortController.token,
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params: queryParams
            })
            .then(response => {
                reposWebApi
                    .get("/partner/minimal", {
                        cancelToken: abortController.token,
                        headers: {
                            Authorization: "Bearer " + accessToken
                        }
                    })
                    .then(res => {
                        reposWebApi
                            .get("/order/get_orders_for_assignment", {
                                cancelToken: abortController.token,
                                headers: {
                                    Authorization: "Bearer " + accessToken
                                }
                            })
                            .then(orders => {
                                if (response?.status === 200) {
                                    dispatch({
                                        type: type,
                                        payload: {
                                            refuellerData: encryptedData(response)?.data,
                                            partnersData: encryptedData(res)?.data,
                                            ordersData: encryptedData(orders)
                                        }
                                    });
                                }
                            });
                    })
                    .catch(error => {
                        if (error?.response?.status === 401) {
                            dispatch({
                                type: "SESSION_EXPIRED",
                                payload: true
                            });
                        }
                        if (error?.response?.status === 500) {
                            dispatch({
                                type: "INTERNAL_SERVER_ERROR",
                                payload: true
                            });
                        }
                    });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const resetDriverPassword = (accessToken, driverId, props) => {
    const requestBody = {
        driver_id: driverId
    };
    return dispatch => {
        dispatch({
            type: "CHANGE_DRIVER_PASSWORD_LOADNG",
            payload: true
        });
        reposWebApi
            .post("/driver/password/reset", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "CHANGE_DRIVER_PASSWORD_SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    props.enqueueSnackbar(encryptedData(response)?.data, {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "top",
                            autoHideDuration: 5000,
                            horizontal: "center"
                        }
                    });
                }
            })
            .catch(error => {
                if (
                    error?.response?.status === 400 ||
                    error?.response?.status === 500
                ) {
                    dispatch({
                        type: "CHANGE_DRIVER_PASSWORD_FAIL",
                        payload: false
                    });
                    props.enqueueSnackbar("Something went wrong!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            autoHideDuration: 5000,
                            horizontal: "center"
                        }
                    });
                }
            });
    };
};

export const resetPartnerPassword = (accessToken, partnerId) => {
    const requestBody = {
        partner_id: partnerId
    };

    return dispatch => {
        dispatch({
            type: "CHANGE_PARTNER_PASSWORD_LOADING",
            payload: true
        });
        reposWebApi
            .post("/partner/password/reset", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "CHANGE_PARTNER_PASSWORD_SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    alert(encryptedData(response)?.data);
                }
            })
            .catch(error => {
                if (error?.response?.status === 400 || error.response?.status === 500) {
                    dispatch({
                        type: "CHANGE_PARTNER_PASSWORD_FAIL",
                        payload: false
                    });
                }
            });
    };
};

export const resetCustomerPassword = (accessToken, customerId, props) => {
    const requestBody = {
        customer_id: customerId
    };

    return dispatch => {
        dispatch({
            type: "CHANGE_CUSTOMER_PASSWORD_LOADING",
            payload: true
        });
        reposWebApi
            .post("/customer/password/reset", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "CHANGE_CUSTOMER_PASSWORD_SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    props.enqueueSnackbar(encryptedData(response)?.data, {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "top",
                            autoHideDuration: 5000,
                            horizontal: "center"
                        }
                    });
                }
            })
            .catch(error => {
                if (
                    error?.response?.status === 400 ||
                    error?.response?.status === 500
                ) {
                    dispatch({
                        type: "CHANGE_CUSTOMER_PASSWORD_FAIL",
                        payload: false
                    });
                    props.enqueueSnackbar("Something went wrong!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            autoHideDuration: 5000,
                            horizontal: "center"
                        }
                    });
                }
            });
    };
};

// Write Operations

export const createPartner = (accessToken, partnerForm) => {
    let requestBody = {};

    if (partnerForm.mobileAvailablityStatus === 406) {
        if (partnerForm.gst_number === "") {
            alert("No GST Entered");
        }
        if (partnerForm.pan_card === "") {
            alert("No PAN Entered");
        } else {
            requestBody = {
                mobile: partnerForm.mobile,
                full_name: partnerForm.ownerName,
                password: partnerForm.password,
                city_name: partnerForm.city,
                pincode: partnerForm.pinCode,
                state: partnerForm.state,
                pump_address: partnerForm.address,
                pump_address_lat: partnerForm.latitude,
                pump_address_lon: partnerForm.longitude,
                pp_license_number: partnerForm.licenseNumber,
                pump_name: partnerForm.pumpName,
                pump_brand: partnerForm.dealersOf,
                user_exists: partnerForm.user_exists,
                pan_card: partnerForm.pan_card,
                gst_number: partnerForm.gst_number,
                vat_tin_number: partnerForm.vat_tin_number,
                cst_tin_number: partnerForm.cst_tin_number,
                bank_account_number: partnerForm.bank_account_number,
                ifsc: partnerForm.ifsc,
                zone: partnerForm.zoneSelected,
                region: partnerForm.regionSelected,
                monetized: partnerForm.monetized,
                partner_type: partnerForm.partner_type,
                repos_share: partnerForm.repos_share,
                asd: partnerForm.asd,
                automation: partnerForm.automation
            };
        }
    }

    if (partnerForm.availablityStatus === 406) {
        if (partnerForm.gst_number === "") {
            alert("No GST Entered");
        }
        if (partnerForm.pan_card === "") {
            alert("No PAN Entered");
        } else {
            requestBody = {
                email: partnerForm.email,
                full_name: partnerForm.ownerName,
                password: partnerForm.password,
                city_name: partnerForm.city,
                state: partnerForm.state,
                pincode: partnerForm.pinCode,
                pump_address: partnerForm.address,
                pump_address_lat: partnerForm.latitude,
                pump_address_lon: partnerForm.longitude,
                pp_license_number: partnerForm.licenseNumber,
                pump_name: partnerForm.pumpName,
                pump_brand: partnerForm.dealersOf,
                user_exists: partnerForm.user_exists,
                pan_card: partnerForm.pan_card,
                gst_number: partnerForm.gst_number,
                vat_tin_number: partnerForm.vat_tin_number,
                cst_tin_number: partnerForm.cst_tin_number,
                bank_account_number: partnerForm.bank_account_number,
                ifsc: partnerForm.ifsc,
                zone: partnerForm.zoneSelected,
                region: partnerForm.regionSelected,
                monetized: partnerForm.monetized,
                partner_type: partnerForm.partner_type,
                repos_share: partnerForm.repos_share,
                asd: partnerForm.asd,
                automation: partnerForm.automation
            };
        }
    }

    if (
        partnerForm.availablityStatus === 200 &&
        partnerForm.mobileAvailablityStatus === 200
    ) {
        if (partnerForm.gst_number === "") {
            alert("No GST Entered");
        }
        if (partnerForm.pan_card === "") {
            alert("No PAN Entered");
        } else {
            requestBody = {
                mobile: partnerForm.mobile,
                full_name: partnerForm.ownerName,
                password: partnerForm.password,
                city_name: partnerForm.city,
                pincode: partnerForm.pinCode,
                state: partnerForm.state,
                pump_address: partnerForm.address,
                pump_address_lat: partnerForm.latitude,
                pump_address_lon: partnerForm.longitude,
                pp_license_number: partnerForm.licenseNumber,
                pump_name: partnerForm.pumpName,
                email: partnerForm.email,
                pump_brand: partnerForm.dealersOf,
                user_exists: 0,
                pan_card: partnerForm.pan_card,
                gst_number: partnerForm.gst_number,
                vat_tin_number: partnerForm.vat_tin_number,
                cst_tin_number: partnerForm.cst_tin_number,
                bank_account_number: partnerForm.bank_account_number,
                ifsc: partnerForm.ifsc,
                zone: partnerForm.zoneSelected,
                region: partnerForm.regionSelected,
                monetized: partnerForm.monetized,
                partner_type: partnerForm.partner_type,
                repos_share: partnerForm.repos_share,
                asd: partnerForm.asd,
                automation: partnerForm.automation
            };
        }
    }

    return dispatch => {
        dispatch({
            type: "CREATING_PARTNER",
            payload: true
        });
        reposWebApi
            .post("/partner/partner_pump_create", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "CREATE_PARTNER_SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    alert("Pump Created Successfully");
                }
            })
            .catch(error => {
                if (error.response?.status === 400 || error.response?.status === 500) {
                    dispatch({
                        type: "CREATE_PARTNER_FAIL",
                        payload: false
                    });
                }
                if (error.response?.status === 500) {
                    // dispatch({
                    //     type: 'INTERNAL_SERVER_ERROR',
                    //     payload: true
                    // });
                    alert("INTERNAL SERVER ERROR please try after sometime..!");
                }
                if (error.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
            });
    };
};

export const editPartner = (accessToken, partnerForm) => {
    let requestBody = {};
    requestBody = {
        partner_id: partnerForm.partnerId,
        mobile: partnerForm.mobile,
        full_name: partnerForm.ownerName,
        city_name: partnerForm.city,
        state: partnerForm.state,
        pincode: partnerForm.pinCode,
        pump_address: partnerForm.address,
        pump_address_lat: partnerForm.latitude,
        pump_address_lon: partnerForm.longitude,
        pp_license_number: partnerForm.licenseNumber,
        pump_name: partnerForm.pumpName,
        email: partnerForm.email,
        pump_brand: partnerForm.dealersOf,
        pan_card: partnerForm.pan_card,
        gst_number: partnerForm.gst_number,
        vat_tin_number: partnerForm.vat_tin_number,
        cst_tin_number: partnerForm.cst_tin_number,
        bank_account_number: partnerForm.bank_account_number,
        ifsc: partnerForm.ifsc,
        zone: partnerForm.zoneSelected,
        region: partnerForm.regionSelected,
        partner_type: partnerForm.partner_type,
        automation: partnerForm.automation
    };

    return dispatch => {
        dispatch({
            type: "EDIT_PARTNER",
            payload: true
        });
        reposWebApi
            .post("/partner/partner_pump_edit", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "EDIT_PARTNER_SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    alert("Partner Modified Successfully");
                }
                dispatch(
                    sendLogs(
                        accessToken,
                        secureLocalStorage.getItem("mobile"),
                        "/partner/partner_pump_edit",
                        qs.stringify(requestBody)
                    )
                );
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    dispatch({
                        type: "EDIT_PARTNER_FAIL",
                        payload: false
                    });
                }
                if (error.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
                if (error.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
            });
    };
};

export const assignOrderToRefueler = (
    accessToken,
    orderId,
    refuelerId,
    props
) => {
    const requestBody = {
        refueler_id: refuelerId,
        order_id: orderId
    };

    return dispatch => {
        reposWebApi
            .post("/order/assign_order_to_refueler", requestBody, {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "REFUELER_ASSIGNMENT_SUCCESS",
                        payload: encryptedData(response)?.data
                    });

                    props.enqueueSnackbar("Status updated successfully!", {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });

                    dispatch(getAllRefuellersAndOrders(accessToken));
                }
                dispatch(
                    sendLogs(
                        accessToken,
                        secureLocalStorage.getItem("mobile"),
                        "/order/assign_order_to_refueler",
                        qs.stringify(requestBody)
                    )
                );
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    dispatch(
                        sendLogs(
                            accessToken,
                            secureLocalStorage.getItem("mobile"),
                            "/order/assign_order_to_refueler",
                            qs.stringify(requestBody)
                        )
                    );
                    dispatch({
                        type: "REFUELER_ASSIGNMENT_FAIL",
                        payload: false
                    });
                }
                props.enqueueSnackbar("Something Went Wrong..!", {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center"
                    }
                });
            });
    };
};

export const changeOrderAssignmentSequence = (
    accessToken,
    orderId,
    operation
) => {
    const requestBody = {
        order_id: orderId,
        operation: operation
    };

    return dispatch => {
        reposWebApi
            .post("/order/change_order_assignment_sequence", requestBody, {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "REFUELER_ASSIGNMENT_SEQUENCE_CHANGED",
                        payload: encryptedData(response)?.data
                    });
                }
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    dispatch({
                        type: "REFUELER_ASSIGNMENT_SEQUENCE_CHANGE_FAILED",
                        payload: false
                    });
                }
            });
    };
};

export const changeOrderUnssignment = (accessToken, orderId) => {
    const requestBody = {
        order_id: orderId
    };

    return dispatch => {
        reposWebApi
            .post("/order/unassign_order", requestBody, {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch(
                        sendLogs(
                            accessToken,
                            secureLocalStorage.getItem("mobile"),
                            "/order/unassign_order",
                            qs.stringify(requestBody)
                        )
                    );
                    dispatch({
                        type: "ORDER_UNASSIGN_FROM_REFUELER",
                        payload: encryptedData(response)?.data
                    });
                }
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    dispatch(
                        sendLogs(
                            accessToken,
                            secureLocalStorage.getItem("mobile"),
                            "/order/unassign_order",
                            qs.stringify(requestBody)
                        )
                    );
                    dispatch({
                        type: "ORDER_UNASSIGN_FROM_REFUELER_FAILED",
                        payload: false
                    });
                }
            });
    };
};

export const changeActivationStatus = (accessToken, status, id) => {
    const requestBody = {
        refueler_id: id,
        is_active: status
    };

    return dispatch => {
        reposWebApi
            .post("/refueler/refueler_active_inactive", requestBody, {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "CHANGE_REFUELER_STATUS",
                        payload: encryptedData(response)?.data
                    });
                    window.location.reload();
                }
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    dispatch({
                        type: "CHANGE_REFUELER_STATUS_FAILED",
                        payload: false
                    });
                }
            });
    };
};

export const modifyGeofence = (accessToken, geofenceId, polygonArray) => {
    const requestBody = {
        geofence_id: geofenceId,
        lat_lon_polygon: JSON.stringify(polygonArray)
    };

    return dispatch => {
        dispatch({
            type: "MODIFY_GEOFENCE_LOADING"
        });
        reposWebApi
            .post("/customer/update_geofence", requestBody, {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                dispatch({
                    type: "MODIFY_GEOFENCE",
                    payload: response.data
                });
                // alert("Your geo-fence location has been updated");
                // window.location.reload();
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
            });
    };
};

export const changeOrderStatus = (
    accessToken,
    order,
    status,
    action,
    deliveredQuantity,
    deliveryMode,
    props
) => {
    let requestBody;
    if (action === "1") {
        if (order.length === 1) {
            requestBody = {
                order_status: status,
                order_id: JSON.stringify(order[0]),
                lmd_dispensed_quantity: deliveredQuantity,
                lmd_order_mode: deliveryMode
            };
        } else {
            requestBody = {
                order_status: status,
                order_id_arr: JSON.stringify(order)
            };
        }
    } else {
        requestBody = {
            order_status: status,
            order_id: order
        };
    }

    return dispatch => {
        dispatch({
            type: "CHANGE_ORDER_STATUS_LOADING"
        });
        reposWebApi
            .post("/order/change_order_status_lmd", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "CHANGE_ORDER_STATUS",
                        payload: response.data
                    });
                    props.enqueueSnackbar("Status updated successfully!", {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                }
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error.response?.status === 500) {
                    props.enqueueSnackbar("Something went wrong!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "CHANGE_ORDER_STATUS_ERROR",
                        payload: true
                    });
                }
            });
    };
};

// export const changePaymentStatus =(accessToken, order, status, action, props)=>{
//
//     let requestBody;
//
//     if (action === "2"){
//         requestBody = {
//             payment_mode:status,
//             order_id_arr: JSON.stringify(order)
//         };
//     }
//     else {
//         requestBody = {
//             payment_mode:status,
//             order_id: order
//         };
//     }
//
//     return (dispatch) =>{
//         dispatch({
//             type: 'CHANGE_PAYMENT_STATUS_LOADING',
//         });
//         reposWebApi.post('/order/lmd_mark_as_paid', qs.stringify(requestBody), { headers: {
//                 Authorization: 'Bearer '+ accessToken
//             }})
//             .then(response=>{
//                 if (response?.status === 200){
//                     props.enqueueSnackbar('Payment mode updated successfully!',{
//                         variant:'success',
//                         anchorOrigin:{
//                             vertical:'top',
//                             horizontal:'center',
//                         },
//                     })
//                     setTimeout(
//                         function()
//                         { window.location.reload() },
//                         2000);
//                 }
//                 dispatch({
//                     type: 'CHANGE_PAYMENT_STATUS',
//                     payload: response.data
//                 });
//             })
//             .catch(error => {
//                 if (error.response?.status === 401) {
//                     dispatch({
//                         type: 'SESSION_EXPIRED',
//                         payload: true
//                     });
//                 }
//                 else {
//                     props.enqueueSnackbar('Something went wrong!',{
//                         variant:'error',
//                         anchorOrigin:{
//                             vertical:'top',
//                             horizontal:'center',
//                         },
//                     })
//                     dispatch({
//                         type: 'ERROR',
//                         payload: true
//                     });
//                 }
//             });
//     }
//
// };

export const createOperator = (accessToken, operatorForm, partnerId) => {
    const requestBody = {
        full_name: operatorForm.fullName,
        mobile: operatorForm.mobile,
        password: operatorForm.password,
        email: operatorForm.email,
        user_name: operatorForm.user_name,
        license_number: operatorForm.licenseNumber,
        insurance: operatorForm.insurance,
        partner_id: partnerId
    };

    return dispatch => {
        if (operatorForm.reset) {
            dispatch({
                type: "CREATE_OPERATOR_AGAIN",
                payload: false
            });
        } else {
            dispatch({
                type: "CREATING_OPERATOR",
                payload: true
            });
            reposWebApi
                .post("/partner/create_driver", qs.stringify(requestBody), {
                    headers: {
                        Authorization: "Bearer " + accessToken
                    }
                })
                .then(response => {
                    if (response?.status === 200) {
                        dispatch({
                            type: "CREATING_OPERATOR_SUCCESS",
                            payload: encryptedData(response)?.data
                        });
                        // setTimeout(function(){ window.location.reload(); }, 2000);
                        alert("Operator Created Successfully");
                    }
                })
                .catch(error => {
                    if (error.response?.status === 400) {
                        dispatch({
                            type: "CREATING_OPERATOR_FAIL",
                            payload: false
                        });
                    }
                    if (error.response?.status === 500) {
                        dispatch({
                            type: "INTERNAL_SERVER_ERROR",
                            payload: true
                        });
                    } else if (error.response?.status === 403) {
                        dispatch({
                            type: "CREATING_OPERATOR_FAIL",
                            payload: false
                        });
                        alert(encryptedData(error?.response)?.data);
                    }
                });
        }
    };
};

export const createUser = (accessToken, userForm, props) => {
    const requestBody = {
        name: userForm.user_name,
        mobile: userForm.mobile,
        email: userForm.email,
        department: userForm.departmentSelected,
        region: userForm.regionSelected,
        state_list: userForm.state_list,
        access_type: userForm.accessTypeSelected,
        is_crm: userForm.crmToggled,
        repos_id: userForm.repos_id,
        rank: userForm.rank,
        state: userForm.state_area,
        territory: userForm.territory,
        reporting_to: userForm.reporting_to
    };

    return dispatch => {
        dispatch({
            type: "CREATING_USER",
            payload: true
        });
        reposWebApi
            .post(
                "/customer/superadminusers/list/register",
                qs.stringify(requestBody),
                {
                    headers: {
                        Authorization: "Bearer " + accessToken
                    }
                }
            )
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "CREATING_USER_SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    props.enqueueSnackbar("User Created Successfully", {
                        variant: "Success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch(getAllUsers(accessToken));
                }
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    props.enqueueSnackbar("Bad Request..!", {
                        variant: "info",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "CREATING_USER_FAIL",
                        payload: false
                    });
                }
                if (error.response?.status === 500) {
                    props.enqueueSnackbar("Internal Server Error..!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                } else if (error.response?.status === 403) {
                    props.enqueueSnackbar("Already a Super-admin User!", {
                        variant: "info",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "CREATING_USER_FAIL",
                        payload: false
                    });
                    //alert(encryptedData(error?.response)?.data)
                } else if (error?.response?.status === 406) {
                    props.enqueueSnackbar("Please Sign-up for Customer!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "CREATING_USER_FAIL",
                        payload: false
                    });
                    //alert(encryptedData(error?.response)?.data)
                }
            });
    };
};

export const deleteUser = (accessToken, id, props) => {
    const requestBody = {
        id: id
    };

    return dispatch => {
        reposWebApi
            .post("/customer/superadminuser/delete", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch(getAllUsers(accessToken));
                    props.enqueueSnackbar(encryptedData(response)?.data, {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "DELETE_USER",
                        payload: encryptedData(response)?.data
                    });
                }
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error.response?.status === 400) {
                    props.enqueueSnackbar(error?.data?.data, {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                }
                if (error.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const editOperator = (accessToken, operatorForm) => {
    const requestBody = {
        full_name: operatorForm.fullName,
        mobile: operatorForm.mobile,
        password: operatorForm.password,
        email: operatorForm.email,
        user_name: operatorForm.user_name,
        license_number: operatorForm.licenseNumber,
        insurance: operatorForm.insurance,
        partner_id: operatorForm.partner,
        driver_id: operatorForm.id,
        is_active: 1
    };

    return dispatch => {
        dispatch({
            type: "EDITING_OPERATOR",
            payload: true
        });
        reposWebApi
            .post("/partner/edit_driver", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "EDITING_OPERATOR_SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    alert("Operator Edited Successfully!");
                }
            })
            .catch(error => {
                if (error.response?.status === 400 || error.response?.status === 500) {
                    dispatch({
                        type: "EDITING_OPERATOR_FAIL",
                        payload: false
                    });
                }
                if (error.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const resetFormCreateRefueler = () => {
    return dispatch => {
        dispatch({
            type: "CREATING_REFUELER_RESET"
        });
    };
};

export const createRefueler = (accessToken, refuelerFormData) => {
    const requestBody = {
        partner: refuelerFormData.selectedPartner,
        driver: refuelerFormData.selectedDriver,
        tracker_device: refuelerFormData.selectedTracker,
        vehicle_registration_number: refuelerFormData.values.registrationNumber,
        net_capacity: refuelerFormData.values.capacity,
        self_pickup_rpp: refuelerFormData.values.setPickup,
        authorisation: refuelerFormData.values.authorisation ? 1 : 0
    };

    return dispatch => {
        dispatch({
            type: "CREATING_REFUELER",
            payload: true
        });
        reposWebApi
            .post("/refueler/create_refueler", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 201) {
                    dispatch({
                        type: "CREATING_REFUELER_SUCCESS",
                        payload: true
                    });
                    alert("RPP Created Successfully");
                }
            })
            .catch(error => {
                if (error?.response?.status === 400 || error.response?.status === 500) {
                    dispatch({
                        type: "CREATING_REFUELER_FAIL",
                        payload: false
                    });
                }
                if (error.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
                if (error.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
            });
    };
};

export const activateRpp = (accessToken, refuelerId, activationStatus) => {
    const requestBody = {
        refueler_id: refuelerId,
        is_active: activationStatus
    };

    return dispatch => {
        dispatch({
            type: "ACTIVATING_REFUELER",
            payload: true
        });
        reposWebApi
            .post(
                "/refueler/make_refueler_active_inactive",
                qs.stringify(requestBody),
                {
                    headers: {
                        Authorization: "Bearer " + accessToken
                    }
                }
            )
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "ACTIVATING_REFUELER_SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    alert(encryptedData(response)?.data);
                    window.location.reload();
                }
            })
            .catch(error => {
                dispatch({
                    type: "ACTIVATING_REFUELER_FAIL",
                    payload: error.response.status
                });
                if (error.response?.status === 500) {
                    dispatch({
                        type: "ACTIVATING_REFUELER_FAIL",
                        payload: error.response.status
                    });
                    alert("Something Went Wrong.");
                }
                if (error.response?.status === 501) {
                    dispatch({
                        type: "ACTIVATING_REFUELER_FAIL",
                        payload: error.response.status
                    });
                    alert(
                        "Cannot activate this RPP because no tracker was assigned to it!"
                    );
                }
                if (error.response?.status === 502) {
                    dispatch({
                        type: "ACTIVATING_REFUELER_FAIL",
                        payload: error.response.status
                    });
                    alert(
                        "Cannot activate this RPP because no Driver was assigned to it!"
                    );
                }
            });
    };
};

export const reloadPartner = () => {
    return dispatch => {
        dispatch({
            type: "EDIT_PARTNER_RELOAD"
        });
    };
};

export const reloadUser = () => {
    return dispatch => {
        dispatch({
            type: "EDIT_USER_RELOAD"
        });
    };
};

export const sendLogs = (accessToken, mobile, url_endpoint, u_json) => {
    const requestBody = {
        mobile: mobile,
        url_endpoint: url_endpoint,
        u_json: u_json
    };

    return dispatch => {
        dispatch({
            type: "SENDING_LOGS",
            payload: true
        });
        reposWebApi
            .post("/customer/superadminuser/log", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "SENDING_LOGS_SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    //alert("success")
                }
            })
            .catch(error => {
                dispatch({
                    type: "SENDING_LOGS_FAIL",
                    payload: error.response.status
                });
                if (error.response?.status === 500) {
                    dispatch({
                        type: "SENDING_LOGS_FAIL",
                        paylaod: error.response.status
                    });
                    //alert("fails")
                }
            });
    };
};

export const AddFleetCart = (
    accessToken,
    amount,
    date_of_transaction,
    transition_reference_id,
    customer_id
) => {
    const requestBody = {
        amount: amount,
        date_of_transaction: date_of_transaction,
        transition_reference_id: transition_reference_id,
        customer_id: customer_id
    };

    return dispatch => {
        dispatch({
            type: "ADD_FLEET_CARD",
            payload: true
        });
        reposWebApi
            .post("/payment/fleet_card_payment ", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "ADD_FLEET_CARD_SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    //alert("success")
                }
            })
            .catch(error => {
                dispatch({
                    type: "ADD_FLEET_CARD_FAILED",
                    payload: error.response.status
                });
                if (error.response?.status === 500) {
                    dispatch({
                        type: "ADD_FLEET_CARD_FAILED",
                        paylaod: error.response.status
                    });
                    //alert("fails")
                }
            });
    };
};

export const getFleetCardPayments = (
    accessToken,
    searchTerm,
    limit,
    offset,
    fromDate,
    toDate,
    abortController
) => {
    let type = "GET_ALL_FLEET_CARD_PAYMENTS";
    let queryParams = {};

    if (searchTerm !== "") {
        type = "GET_ALL_FLEET_CARD_PAYMENTS_SEARCH";
        queryParams.search = searchTerm;
    } else {
        type = "GET_ALL_FLEET_CARD_PAYMENTS";
    }

    if (limit !== null && offset !== null) {
        type = "GET_ALL_FLEET_CARD_PAYMENTS_BY_LIMIT";
        queryParams.limit = limit;
        queryParams.offset = offset;
    }

    // eslint-disable-next-line
    if (
        fromDate !== null ||
        (fromDate !== "" && toDate !== null) ||
        toDate !== ""
    ) {
        type = "GET_ALL_FLEET_CARD_PAYMENTS_BY_DATE";
        queryParams.fromDate = fromDate;
        queryParams.toDate = toDate;
    }

    return dispatch => {
        dispatch({
            type: "GET_ALL_FLEET_CARD_PAYMENTS_LOADING",
            payload: true
        });
        reposWebApi
            .get("/payment/get_fleet_card_status", {
                cancelToken: abortController.token,
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params: queryParams
            })
            .then(response => {
                queryParams.offset = encryptedData(response)?.data?.results?.length;
                if (response?.status === 200) {
                    dispatch({
                        type: type,
                        payload: encryptedData(response)?.data
                    });
                }
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                } else if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                } else {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getServiceRequest = (
    accessToken,
    searchTerm,
    limit,
    offset,
    fromDate,
    toDate,
    abortController
) => {
    let type = "GET_ALL_SERVICE";
    let queryParams = {};

    if (searchTerm !== "") {
        type = "GET_ALL_SERVICE_SEARCH";
        queryParams.search = searchTerm;
    } else {
        type = "GET_ALL_SERVICE";
    }

    if (limit !== null && offset !== null) {
        type = "GET_ALL_SERVICE_BY_LIMIT";
        queryParams.limit = limit;
        queryParams.offset = offset;
    }

    // eslint-disable-next-line
    if (
        fromDate !== null ||
        (fromDate !== "" && toDate !== null) ||
        toDate !== ""
    ) {
        type = "GET_ALL_SERVICE_BY_DATE";
        queryParams.start_date = fromDate;
        queryParams.end_date = toDate;
    }

    return dispatch => {
        dispatch({
            type: "GET_ALL_CUSTOMERS_LOADING",
            payload: true
        });
        reposWebApi
            .get("/executives/complain/all", {
                cancelToken: abortController.token,
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params: queryParams
            })
            .then(response => {
                queryParams.offset = encryptedData(response)?.data?.results?.length;
                if (response?.status === 200) {
                    dispatch({
                        type: type,
                        payload: encryptedData(response)?.data
                    });
                }
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getAllDatumData = accessToken => {
    return dispatch => {
        dispatch({
            type: "GET_DATUM_DATA_LOADING",
            payload: true
        });
        reposWebApi
            .get("/customer/datum_dataAnalytics", {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                dispatch({
                    type: "GET_DATUM_DATA",
                    payload: encryptedData(response)?.data
                });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getAllCustomerData = accessToken => {
    return dispatch => {
        dispatch({
            type: "GET_CUSTOMER_DATA_LOADING",
            payload: true
        });
        reposWebApi
            .get("/customer/get_data_analytics", {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                dispatch({
                    type: "GET_CUSTOMER_DATA",
                    payload: encryptedData(response)?.data
                });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getAllPartnerData = accessToken => {
    return dispatch => {
        dispatch({
            type: "GET_PARTNER_DATA_LOADING",
            payload: true
        });
        reposWebApi
            .get("/partner/get_partner_analytics", {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                dispatch({
                    type: "GET_PARTNER_DATA",
                    payload: encryptedData(response)?.data
                });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getAllOrderData = accessToken => {
    return dispatch => {
        dispatch({
            type: "GET_ORDER_DATA_LOADING",
            payload: true
        });
        reposWebApi
            .get("/order/orders_data_analytics", {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                dispatch({
                    type: "GET_ORDER_DATA",
                    payload: encryptedData(response)?.data
                });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getOrderStatus = (accessToken, state) => {
    return dispatch => {
        dispatch({
            type: "GET_ORDER_STATUS_LOADING",
            payload: true
        });
        reposWebApi
            .get("/order/get_order_status_count", {
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params: {state: state}
            })
            .then(response => {
                dispatch({
                    type: "GET_ORDER_STATUS",
                    payload: encryptedData(response)?.data
                });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getDatumDetails = (
    accessToken,
    limit,
    offset,
    searchTerm,
    filter,
    fromDate,
    toDate,
    datumStatus,
    abortController
) => {
    let type = "GET_DATUM_DETAILS";
    let queryParams = {};

    if (searchTerm !== "") {
        type = "GET_DATUM_DETAILS_SEARCH";
        queryParams.search = searchTerm;
    } else {
        type = "GET_DATUM_DETAILS";
    }

    if (filter !== "") {
        type = "GET_DATUM_DETAILS_FILTER";
        queryParams.state = filter;
    } else {
        type = "GET_DATUM_DETAILS";
    }

    if (limit !== null && offset !== null) {
        type = "GET_DATUM_DETAILS_LIMIT";
        queryParams.limit = limit;
        queryParams.offset = offset;
    }

    if (
        fromDate !== null ||
        (fromDate !== "" && toDate !== null) ||
        toDate !== ""
    ) {
        type = "GET_DATUM_DETAILS_BY_DATE";
        queryParams.start_date = fromDate;
        queryParams.end_date = toDate;
    }

    if (datumStatus !== "") {
        type = "GET_DATUM_DETAILS_STATUS";
        queryParams.datum_status = datumStatus;
    } else {
        type = "GET_DATUM_DETAILS";
    }

    return dispatch => {
        dispatch({
            type: "GET_DATUM_DETAILS_LOADING",
            payload: true
        });
        reposWebApi
            .get("/customer/get_list_datum_details", {
                cancelToken: abortController?.token,
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params: queryParams
            })
            .then(response => {
                dispatch({
                    type: type,
                    payload: encryptedData(response)?.data
                });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getDatumAnalytics = (accessToken, state) => {
    return dispatch => {
        dispatch({
            type: "GET_DATUM_ANALYTICS_LOADING",
            payload: true
        });
        reposWebApi
            .get("/customer/datum_dataAnalytics", {
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params: {state: state}
            })
            .then(response => {
                dispatch({
                    type: "GET_DATUM_ANALYTICS",
                    payload: encryptedData(response)?.data
                });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const sendReportToMail = (accessToken, params, props) => {
    return dispatch => {
        props.enqueueSnackbar(
            "The report will be sent within 15 minutes to your email.",
            {
                variant: "info",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center"
                }
            }
        );
        reposWebApi
            .get("/order/send_all_order_report", {
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params
            })
            .then(response => {
                if (response.status === 200) {
                    props.enqueueSnackbar(encryptedData(response)?.data, {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                }
            })
            .catch(error => {
                // if (error?.response?.status === 401) {
                //     dispatch({
                //         type: 'SESSION_EXPIRED',
                //         payload: true
                //     });
                // }
                // if (error?.response?.status === 500) {
                //     dispatch({
                //         type: 'INTERNAL_SERVER_ERROR',
                //         payload: true
                //     });
                // }
            });
    };
};

export const versionControl = () => {
    return async dispatch => {
        await reposWebApi
            .get("/utilities/get_version_web", {})
            .then(res => {
                if (encryptedData(res)?.data?.superadmin_version !== version) {
                    alert("New app version detected please refresh the page");
                    window.location.reload();
                    version = encryptedData(res)?.data?.customer_version;
                }
            })
            .catch(error => {
            });
    };
};

export const getSubAssetAnalytics = (
    accessToken,
    customerId,
    fromDate,
    toDate
) => {
    let queryParams = {};

    if (customerId !== null) {
        queryParams.customer_id = customerId;
    }

    if (
        (fromDate !== null || fromDate !== "") &&
        (toDate !== null || toDate !== "")
    ) {
        queryParams.start_date = fromDate;
        queryParams.end_date = toDate;
    }

    return dispatch => {
        dispatch({
            type: "GET_SUB_ASSET_ANALYTICS_LOADING",
            payload: true
        });
        reposWebApi
            .get("/order/sub_customer_details", {
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params: queryParams
            })
            .then(response => {
                dispatch({
                    type: "GET_SUB_ASSET_ANALYTICS",
                    payload: encryptedData(response)?.data
                });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getCustomerAnalytics = (
    accessToken,
    customerId,
    fromDate,
    toDate
) => {
    let queryParams = {};

    if (customerId !== null) {
        queryParams.customer_id = customerId;
    }

    if (
        (fromDate !== null || fromDate !== "") &&
        (toDate !== null || toDate !== "")
    ) {
        queryParams.start_date = fromDate;
        queryParams.end_date = toDate;
    }

    return dispatch => {
        dispatch({
            type: "GET_CUSTOMER_ANALYTICS_LOADING",
            payload: true
        });
        reposWebApi
            .get("/order/customer_data", {
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params: queryParams
            })
            .then(response => {
                dispatch({
                    type: "GET_CUSTOMER_ANALYTICS",
                    payload: encryptedData(response)?.data
                });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const AddLead = (
    accessToken,
    userForm,
    enqueueSnackbar,
    handleDrawerClose,
    getLeadList
) => {
    return dispatch => {
        dispatch({
            type: "ADD_LEAD",
            payload: true
        });
        reposWebApi
            .post("/crm/add_lead", qs.stringify(userForm), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "ADD_LEAD_SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    enqueueSnackbar("Lead Added Successfully", {
                        variant: "Success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    getLeadList();
                    handleDrawerClose();
                    dispatch(
                        sendLogs(
                            accessToken,
                            secureLocalStorage?.getItem('mobile'),
                            "/crm/add_lead",
                            qs.stringify(userForm)
                        )
                    );
                }
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    enqueueSnackbar(encryptedData(error?.response)?.data, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "ADD_LEAD_FAIL",
                        payload: false
                    });
                }
                if (error.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getAllLeads = (accessToken, params, enqueueSnackbar) => {
    if (search) {
        search.abort();
    }

    search = new AbortController();
    const signal = search.signal;

    return dispatch => {
        dispatch({
            type: "GET_ALL_LEADS_LOADING",
            payload: true
        });
        reposWebApi
            .get("/crm/list_lead", {
                signal: signal,
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "GET_ALL_LEADS",
                        payload: encryptedData(response)?.data
                    });

                    dispatch(getLeadOwner(secureLocalStorage.getItem("accessToken")));

                    dispatch({
                        type: "GET_LEAD_ANALYTICS_LOADING",
                        payload: true
                    });
                    reposWebApi
                        .get("/crm/get_lead_analytics", {
                            headers: {
                                Authorization: "Bearer " + accessToken
                            },
                            params: params
                        })
                        .then(response => {
                            dispatch({
                                type: "GET_LEAD_ANALYTICS",
                                payload: encryptedData(response)?.data
                            });
                        })
                        .catch(error => {
                            if (error?.response?.status === 401) {
                                dispatch({
                                    type: "SESSION_EXPIRED",
                                    payload: true
                                });
                            }
                            if (error?.response?.status === 500) {
                                dispatch({
                                    type: "INTERNAL_SERVER_ERROR",
                                    payload: true
                                });
                            }
                        });
                }
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error.response?.status === 500) {
                    enqueueSnackbar("Internal Server Error..!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                }
            });
    };
};

let search = "";
export const getLeadOwner = accessToken => {
    if (search) {
        search.abort();
    }
    search = new AbortController();

    const signal = search.signal;

    return dispatch => {
        reposWebApi
            .get("/crm/list_crm_users/lead_owner", {
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                signal: signal,
                params: {
                    limit: 500
                }
            })
            .then(response => {
                dispatch({
                    type: "GET_LEAD_OWNER",
                    payload: encryptedData(response)?.data
                });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getCumminsLeadOwner = (accessToken, param, setIsLoading) => {
    if (search) {
        search.abort();
    }
    search = new AbortController();

    const signal = search.signal;

    return dispatch => {
        setIsLoading(true);
        reposWebApi
            .get("/crm/list_cummins_crm_users/lead_owner", {
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                signal: signal,
                params: param
            })
            .then(response => {
                dispatch({
                    type: "GET_CUMMINS_LEAD_OWNER",
                    payload: encryptedData(response)?.data
                });
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getRegionsAndState = (accessToken, enqueueSnackbar, param) => {
    return dispatch => {
        dispatch({
            type: "IS_LOADING",
            payload: true
        });
        enqueueSnackbar("State Loading", {
            variant: "info",
            anchorOrigin: {
                vertical: "top",
                horizontal: "center"
            }
        });
        reposWebApi
            .post("/crm/get_state", qs.stringify(param), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "GET_STATE",
                        payload: Object.entries(encryptedData(response)).map(
                            ([key, value]) => ({
                                stateCode: key,
                                stateName: value
                            })
                        )
                    });
                    enqueueSnackbar("State Loaded", {
                        variant: "Success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    // dispatch(getAllLeads(accessToken))
                }
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    dispatch({
                        type: "ADD_LEAD_FAIL",
                        payload: false
                    });
                }
                if (error.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getStateByTerritory = (accessToken, enqueueSnackbar, params) => {
    return dispatch => {
        dispatch({
            type: "IS_LOADING",
            payload: true
        });
        enqueueSnackbar("Territory Loading", {
            variant: "info",
            anchorOrigin: {
                vertical: "top",
                horizontal: "center"
            }
        });
        reposWebApi
            .get("/city/territories", {
                params,
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                dispatch({
                    type: "GET_TERRITORIES",
                    payload: encryptedData(response)
                    // response?.data
                });
                enqueueSnackbar("Territory Loaded", {
                    variant: "Success",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center"
                    }
                });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const updateLead = (
    accessToken,
    userForm,
    id,
    enqueueSnackbar,
    handleDrawerClose,
    getLeadList
) => {
    return dispatch => {
        dispatch({
            type: "ADD_LEAD",
            payload: true
        });
        reposWebApi
            .put("/crm/lead/update/" + id, qs.stringify(userForm), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "ADD_LEAD_SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    enqueueSnackbar("Lead Updated successfully", {
                        variant: "Success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    getLeadList();
                    handleDrawerClose();
                    dispatch(
                        sendLogs(
                            accessToken,
                            secureLocalStorage?.getItem('mobile'),
                            "/crm/lead/update/",
                            qs.stringify(userForm)
                        )
                    );
                }
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    enqueueSnackbar(encryptedData(error?.response)?.data, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "ADD_LEAD_FAIL",
                        payload: false
                    });
                }
                if (error.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const deleteLead = (
    accessToken,
    params,
    enqueueSnackbar,
    getLeadList
) => {
    return dispatch => {
        reposWebApi
            .delete("/crm/lead/delete", {
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params: params
            })
            .then(response => {
                if (response?.status === 200) {
                    enqueueSnackbar("Lead Deleted successfully", {
                        variant: "Success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    getLeadList();
                    dispatch(
                        sendLogs(
                            accessToken,
                            secureLocalStorage?.getItem('mobile'),
                            "/crm/lead/delete",
                            qs.stringify(params)
                        )
                    );
                }
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    enqueueSnackbar(encryptedData(error?.response)?.data, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "ADD_LEAD_FAIL",
                        payload: false
                    });
                }
                if (error.response?.status === 500) {
                    enqueueSnackbar("Something went wrong..!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                }
            });
    };
};

export const AddRppInfo = (
    accessToken,
    userForm,
    enqueueSnackbar,
    handleDrawerClose,
    getLeadList
) => {
    return dispatch => {
        dispatch({
            type: "ADD_RPP_INFO",
            payload: true
        });
        reposWebApi
            .post("/crm/add_rpp_info", qs.stringify(userForm), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "ADD_RPP_INFO_SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    enqueueSnackbar("Product Details Added Successfully", {
                        variant: "Success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    getLeadList();
                    handleDrawerClose();
                }
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    enqueueSnackbar(encryptedData(error?.response)?.data, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "ADD_RPP_INFO_FAIL",
                        payload: false
                    });
                }
                if (error.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const NegotiationInfo = (
    accessToken,
    userForm,
    enqueueSnackbar,
    handleDrawerClose,
    getLeadList
) => {
    return dispatch => {
        dispatch({
            type: "NEGO_INFO",
            payload: true
        });
        reposWebApi
            .post("/crm/add_neg_info", qs.stringify(userForm), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "NEGO_INFO_SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    enqueueSnackbar("Negotiation Added Successfully", {
                        variant: "Success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    getLeadList();
                    handleDrawerClose();
                    dispatch(
                        sendLogs(
                            accessToken,
                            secureLocalStorage?.getItem('mobile'),
                            "/crm/add_neg_info",
                            qs.stringify(userForm)
                        )
                    );
                }
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    enqueueSnackbar(encryptedData(error?.response)?.data, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "NEGO_INFO_FAIL",
                        payload: false
                    });
                }
                if (error.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const PurchaseOrder = (
    accessToken,
    userForm,
    enqueueSnackbar,
    handleDrawerClose,
    getLeadList
) => {
    const formData = new FormData();
    formData.append("gst_number", userForm.gst_number);
    formData.append("id", userForm.id);
    formData.append("purchase_order", userForm.purchase_order);

    // const data = {
    //     gst_number: userForm.gst_number,
    //     id: userForm.id,
    //     purchase_order: formData?.purchase_order
    // }

    return dispatch => {
        reposWebApi
            .post("/crm/add_po", formData, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "multipart/form-data"
                },
                onUploadProgress: progressEvent => {
                    dispatch({
                        type: "UPLOAD_PROGRESS",
                        payload: (progressEvent.loaded / progressEvent.total) * 100
                    });
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "PURCHASE_ORDER__SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    enqueueSnackbar("Purchase Order Added Successfully", {
                        variant: "Success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    getLeadList();
                    handleDrawerClose();
                    dispatch(
                        sendLogs(
                            accessToken,
                            secureLocalStorage?.getItem('mobile'),
                            "/crm/add_po",
                            qs.stringify(formData)
                        )
                    );
                }
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    enqueueSnackbar(encryptedData(error?.response)?.data, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "PURCHASE_ORDER__FAIL",
                        payload: false
                    });
                }
                if (error.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const BookingInfo = (
    accessToken,
    userForm,
    enqueueSnackbar,
    handleDrawerClose,
    getLeadList
) => {
    return dispatch => {
        reposWebApi
            .post("/crm/add_booking", qs.stringify(userForm), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "BOOKING_INFO_SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    enqueueSnackbar("Booking Info Added Successfully", {
                        variant: "Success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    getLeadList();
                    handleDrawerClose();
                    dispatch(
                        sendLogs(
                            accessToken,
                            secureLocalStorage?.getItem('mobile'),
                            "/crm/add_booking",
                            qs.stringify(userForm)
                        )
                    );
                }
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    enqueueSnackbar(encryptedData(error?.response)?.data, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "BOOKING_INFO_FAIL",
                        payload: false
                    });
                }
                if (error.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getRppPrice = (
    accessToken,
    params,
    enqueueSnackbar,
    handleDrawerClose,
    setShowTable
) => {
    return dispatch => {
        dispatch({
            type: "GET_DISCOUNT_LOADING",
            payload: true
        });
        reposWebApi
            .get("/crm/get_rpp_price", {
                params,
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                dispatch({
                    type: "GET_RPP_PRICE",
                    payload: encryptedData(response)?.data
                });
                enqueueSnackbar("Get Price Successfully", {
                    variant: "Success",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center"
                    }
                });
                handleDrawerClose();
                // setShowTable(true);
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
                if (error?.response?.status === 404) {
                    dispatch({
                        type: "PRICE_NOT_FOUND",
                        paylaod: encryptedData(error?.response)?.data?.message
                    });
                    enqueueSnackbar(encryptedData(error?.response)?.data?.message, {
                        variant: "warning",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    // setShowTable(false)
                }
            });
    };
};

// export const getDiscount = (accessToken, params) => {
//     return dispatch => {
//         dispatch({
//             type: "GET_DISCOUNT_LOADING",
//             payload: true
//         });
//         reposWebApi
//             .get("crm/get_discount", {
//                 params,
//                 headers: {
//                     Authorization: "Bearer " + accessToken
//                 }
//             })
//             .then(response => {
//                 dispatch({
//                     type: "GET_DISCOUNT",
//                     payload: encryptedData(response)?.data
//                 });
//             })
//             .catch(error => {
//                 if (error?.response?.status === 401) {
//                     dispatch({
//                         type: "SESSION_EXPIRED",
//                         payload: true
//                     });
//                 }
//                 if (error?.response?.status === 500) {
//                     dispatch({
//                         type: "INTERNAL_SERVER_ERROR",
//                         payload: true
//                     });
//                 }
//             });
//     };
// };

export const AddDatumInfo = (
    accessToken,
    userForm,
    enqueueSnackbar,
    handleDrawerClose,
    getLeadList
) => {
    return dispatch => {
        dispatch({
            type: "ADD_DATUM_INFO",
            payload: true
        });
        reposWebApi
            .post("/crm/add_datum_info", qs.stringify(userForm), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "ADD_DATUM_INFO_SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    enqueueSnackbar("Product Details Added Successfully", {
                        variant: "Success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    getLeadList();
                    handleDrawerClose();
                }
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    enqueueSnackbar(encryptedData(error?.response)?.data, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "ADD_DATUM_INFO_FAIL",
                        payload: false
                    });
                }
                if (error.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getDatumPrice = (accessToken, params, enqueueSnackbar) => {
    return dispatch => {
        dispatch({
            type: "IS_PRICE"
        });
        reposWebApi
            .get("/crm/get_datum_price", {
                params,
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                dispatch({
                    type: "GET_DATUM_PRICE",
                    payload: encryptedData(response)?.data
                });
                enqueueSnackbar("Get Price Successfully", {
                    variant: "Success",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center"
                    }
                });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
                if (error?.response?.status === 404) {
                    enqueueSnackbar(encryptedData(error?.response)?.data?.message, {
                        variant: "warning",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                }
            });
    };
};

export const AddTracker = (
    accessToken,
    userForm,
    enqueueSnackbar,
    handleDrawerClose
) => {
    return dispatch => {
        dispatch({
            type: "ADD_TRACKER_INFO",
            payload: true
        });
        reposNodeApi
            .post("/gps_urls/add_tracker", qs.stringify(userForm), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 201) {
                    dispatch({
                        type: "ADD_TRACKER_INFO_SUCCESS",
                        payload: response?.data?.data
                    });
                    enqueueSnackbar("Tacker Created Successfully", {
                        variant: "Success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    // getAllRefuellers();
                    handleDrawerClose();
                }
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    enqueueSnackbar(encryptedData(error.response)?.data, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "ADD_TRACKER_INFO_FAIL",
                        payload: false
                    });
                }
                if (error.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const CompanyDetails = (
    accessToken,
    userForm,
    enqueueSnackbar,
    getLeadList
) => {
    return dispatch => {
        reposWebApi
            .post("/crm/add_company_details", qs.stringify(userForm), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "COMPANY_DETAILS_SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    enqueueSnackbar("Company Details Added Successfully", {
                        variant: "Success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    getLeadList();
                }
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    enqueueSnackbar(encryptedData(error?.response)?.data, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "COMPANY_DETAILS_FAIL",
                        payload: false
                    });
                }
                if (error.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getCategory = (accessToken, param) => {
    if (search) {
        search.abort();
    }
    search = new AbortController();

    const signal = search.signal;

    return dispatch => {
        reposWebApi
            .get("/crm/get_category", {
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                signal: signal,
                params: param
            })
            .then(response => {
                dispatch({
                    type: "GET_CATEGORY",
                    payload: encryptedData(response)?.data
                });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getSubCategory = (accessToken, enqueueSnackbar, params) => {
    return dispatch => {
        dispatch({
            type: "IS_LOADING",
            payload: true
        });
        reposWebApi
            .get("/crm/get_sub_category", {
                params,
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                dispatch({
                    type: "GET_SUB_CATEGORY",
                    payload: encryptedData(response)?.data
                });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getCompanyType = (accessToken, enqueueSnackbar, params) => {
    return dispatch => {
        dispatch({
            type: "IS_LOADING",
            payload: true
        });
        reposWebApi
            .get("/crm/get_company_type", {
                params,
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                dispatch({
                    type: "GET_COMPANY_TYPE",
                    payload: encryptedData(response)?.data
                });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getNetworkError = online => {
    return async dispatch => {
        if (!online) {
            dispatch({
                type: "ONLINE"
            });
        } else {
            dispatch({
                type: "OFFLINE"
            });
        }
    };
};

export const AddMCapital = (
    accessToken,
    enable,
    customer_id,
    enqueueSnackbar
) => {
    const requestBody = {
        enable: enable ? 1 : 0,
        customer_id: customer_id
    };

    return dispatch => {
        dispatch({
            type: "Add_M_CAPITAL",
            payload: true
        });
        reposWebApi
            .post("/finserv/toggle/credit_landing", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "ADD_M_CAPITAL_SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    enqueueSnackbar("Successfully", {
                        variant: "Success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    //alert("success")
                }
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    enqueueSnackbar(encryptedData(error?.response)?.data, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "ADD_M_CAPITAL_FAILED",
                        payload: error.response.status
                    });
                }
                if (error.response?.status === 500) {
                    dispatch({
                        type: "ADD_M_CAPITAL_FAILED",
                        paylaod: error.response.status
                    });
                    //alert("fails")
                }
            });
    };
};

/********************************* ***********************************/
/**************************** CRM API's  *****************************/
/********************************* **********************************/

export const getLeadAnalytics = (accessToken, param) => {
    return dispatch => {
        dispatch({
            type: "GET_LEAD_ANALYTICS_LOADING",
            payload: true
        });
        reposWebApi
            .get("/crm/get_lead_analytics", {
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params: param
            })
            .then(response => {
                dispatch({
                    type: "GET_LEAD_ANALYTICS",
                    payload: encryptedData(response)?.data
                });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const getListLogInfo = (accessToken, param) => {
    return dispatch => {
        dispatch({
            type: "GET_LIST_LOG_INFO_LOADING",
            payload: true
        });
        reposWebApi
            .get("/crm/list_log_info", {
                headers: {
                    Authorization: "Bearer " + accessToken
                },
                params: param
            })
            .then(response => {
                dispatch({
                    type: "GET_LIST_LOG_INFO",
                    payload: encryptedData(response)?.data
                    // payload:response.data.data.results
                });
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: "SESSION_EXPIRED",
                        payload: true
                    });
                }
                if (error?.response?.status === 500) {
                    // dispatch({
                    //     type: 'INTERNAL_SERVER_ERROR',
                    //     payload: true
                    // });
                }
            });
    };
};

export const addLogsCrm = (
    accessToken,
    payload,
    enqueueSnackbar,
    handleCompanyDetails
) => {
    return dispatch => {
        reposWebApi
            .post("/crm/add_log", qs.stringify(payload), {
                headers: {
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "ADD_LOG_SUCCESS",
                        payload: encryptedData(response)?.data
                        // payload:response.data.data
                    });
                    enqueueSnackbar("Nurturing Stage Added Successfully", {
                        variant: "Success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    handleCompanyDetails();
                }
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    enqueueSnackbar(encryptedData(error?.response)?.data, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "ADD_LOG_FAIL",
                        payload: false
                    });
                }
                if (error.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const importFile = (accessToken, file, enqueueSnackbar) => {
    const formData = new FormData();
    formData.append("file", file);

    return dispatch => {
        reposWebApi
            .post("/crm/import/", formData, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "multipart/form-data"
                },
                onUploadProgress: progressEvent => {
                    dispatch({
                        type: "UPLOAD_PROGRESS",
                        payload: (progressEvent.loaded / progressEvent.total) * 100
                    });
                }
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "IMPORT_FILE_SUCCESS",
                        payload: encryptedData(response)?.data
                    });
                    enqueueSnackbar("File Upload Successfully", {
                        variant: "Success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                }
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    enqueueSnackbar(encryptedData(error?.response)?.data, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                    dispatch({
                        type: "IMPORT_FILE_FAIL",
                        payload: false
                    });
                }
                if (error.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                }
            });
    };
};

export const openImportFile = () => {
    return dispatch => {
        dispatch({
            type: "OPEN_UPLOAD",
            payload: true
        });
    };
};

export const leadBulkDelete = (enqueueSnackbar, data) => {
    return dispatch => {
        dispatch({
            type: "Load_BULK_DELETE"
        });

        reposWebApi
            .delete("/crm/lead/bulk-delete", {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken"),
                    "Content-Type": "application/json"
                },
                data: data
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "BULK_DELETE",
                        payload: decryption(response).data?.detail
                    });
                    enqueueSnackbar(decryption(response)?.data?.detail, {
                        variant: "Success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                }
            })
            .catch(error => {
                if (error.response?.status === 400) {
                    enqueueSnackbar("Bad Request", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                }
                if (error.response?.status === 500) {
                    dispatch({
                        type: "INTERNAL_SERVER_ERROR",
                        payload: true
                    });
                    enqueueSnackbar("something went wrong..!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                }
                dispatch({
                    type: "ERORR_BULK_DELETE"
                });
            });
    };
};

export const leadReason = (enqueueSnackbar, param) => {
    return dispatch => {
        dispatch({
            type: "LOAD_REASON"
        });

        reposWebApi
            .get("crm/get_reason_for_qualified", {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken"),
                    "Content-Type": "application/json"
                },
                params: param
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "LEAD_REASON",
                        payload: invertArrToJson(decryption(response).data[param?.stage][0])
                    });
                }
            })
            .catch(error => {
                if (error.response?.status === 500) {
                    enqueueSnackbar("something went wrong..!", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                }
            });
    };
};

export const exportReport = (enqueueSnackbar, param) => {
    return dispatch => {
        dispatch({
            type: "EXPORT_REPORT_LOAD"
        });

        reposWebApi
            .get("crm/leads_report", {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken"),
                    "Content-Type": "application/json"
                },
                params: param
            })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: "EXPORT_REPORT"
                    });

                    enqueueSnackbar(decryption(response)?.data, {
                        variant: "Success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                }
            })
            .catch(error => {
                enqueueSnackbar("something went wrong..!", {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center"
                    }
                });
                dispatch({
                    type: "EXPORT_REPORT"
                });
            });
    };
};

export const downloadReport = (enqueueSnackbar, param) => {
    return dispatch => {
        dispatch({
            type: "EXPORT_REPORT_LOAD"
        });

        reposWebApi
            .get("crm/logs_report", {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken"),
                    "Content-Type": "application/json"
                },
                params: param
            })
            .then(response => {
                if (response?.status === 200 || response?.status === 204) {
                    dispatch({
                        type: "EXPORT_REPORT"
                    });

                    enqueueSnackbar(decryption(response)?.data, {
                        variant: "Success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                }
            })
            .catch(error => {
                enqueueSnackbar("something went wrong..!", {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center"
                    }
                });
                dispatch({
                    type: "EXPORT_REPORT"
                });
            });
    };
};

/**************************** End *****************************/