import React, {Component} from "react";
// import { Link } from 'react-router-dom';
// Externals
import classNames from "classnames";
// import moment from 'moment';
import PerfectScrollbar from "react-perfect-scrollbar";
// Material helpers
// Material components
import {
    Button,
    colors,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputLabel,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";

// Shared components
import {Portlet, PortletContent} from "components";

// Component styles
import styles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import NoDatafound from "../../../AllCustomers/components/NoDataInTable";
import Label from "../../../Dashboard/components/Label";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

class UsersTable extends Component {
    state = {
        rowsPerPage: 10,
        page: 0,
        open: false,
        imageUrl: "",
        users: [],
        right: false,
        updatedStatus: "",
        orderId: "",
        dialog_open: false,
        order_qty: 0,
        datumOrderState: []
    };

    handleChangePage = (event, page) => {
        this.props.onPagination(this.props.rowsPerPage, page);
    };

    handleFieldChange = event => {
        this.setState({
            order_qty: event
        });
    };

    handleChangeStatus = () => {
        this.props.getUpdatedStatus(
            this.state.updatedStatus,
            this.state.orderId,
            this.state.order_qty
        );
        this.handleClose();
    };

    handleClose() {
        this.setState({
            ...this.state,
            dialog_open: false
        });
    }

    handleChangeRowsPerPage = event => {
        this.props.onPagination(event.target.value, 0);
    };

    handleUpdateStatus = (event, orderId, status) => {
        this.setState({
            dialog_open: true,
            orderId,
            updatedStatus: event.target.value
        });
    };

    render() {
        const {classes, className, datumOrdersData} = this.props;
        const nf = new Intl.NumberFormat("en-IN");
        const rootClassName = classNames(classes.root, className);

        const orderStatusColors = {
            1: colors.grey[600],
            2: colors.orange[600],
            3: colors.green[600],
            4: colors.purple[600],
            5: colors.yellow[600],
            6: colors.red["A700"]
        };

        const asstType = {
            1: "DATUM",
            2: "SFD",
            4: "SFC",
            10: "MOBILE DATUM"
        };

        if (this.props.isLoading && datumOrdersData.length === 0) {
            return (
                <div className={classes.progressWrapper}>
                    <CircularProgress className={classes.progress}/>
                </div>
            );
        } else
            return (
                <Portlet className={rootClassName}>
                    <PortletContent noPadding>
                        <PerfectScrollbar>
                            <TableContainer style={{maxHeight: 640}}>
                                {this.props?.tabClick === 0 ? (
                                    <Table>
                                        {this.props?.isLoading ? (
                                            <TableCell colSpan={"16"}>
                                                <div className={classes.progressWrapper}>
                                                    <LinearProgress
                                                        fullWidth
                                                        className={classes.progress}
                                                    />
                                                </div>
                                            </TableCell>
                                        ) : null}
                                        <TableHead>
                                            <TableRow
                                                style={{
                                                    backdropFilter: "blur(5px)",
                                                    background: "rgb(255 255 255 / 36%)",
                                                    backgroundBlendMode: "exclusion",
                                                    boxShadow: "2px 3px 7px 0px rgb(0 0 0 / 37%)",
                                                    position: "sticky",
                                                    top: 0,
                                                    zIndex: 10
                                                }}
                                            >
                                                <TableCell align="center">Order Placed at</TableCell>
                                                <TableCell align="center">Customer Name</TableCell>
                                                <TableCell align="center">Order ID</TableCell>
                                                <TableCell align="center">Dispensed To</TableCell>
                                                <TableCell align="center">Ordered Quantity </TableCell>
                                                <TableCell align="center">Dispensed Quantity</TableCell>
                                                <TableCell align="center">Update Status</TableCell>
                                                <TableCell align="center">Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {this.props.dataCount === 0 ? (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={"8"}>
                                                        <NoDatafound/>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                {datumOrdersData?.map(singleDatumOrder => (
                                                    <TableRow
                                                        className={classes.tableRow}
                                                        hover
                                                        key={singleDatumOrder.id}
                                                    >
                                                        <TableCell className={classes.tableCell}>
                                                            <div className={classes.tableCellInner}>
                                                                <Typography
                                                                    className={classes.nameText}
                                                                    variant="body1"
                                                                >
                                                                    {singleDatumOrder.created_at}
                                                                </Typography>
                                                            </div>
                                                        </TableCell>

                                                        <TableCell className={classes.tableCell}>
                                                            {singleDatumOrder.customer_name}
                                                        </TableCell>

                                                        <TableCell className={classes.tableCell}>
                                                            {singleDatumOrder.id}
                                                        </TableCell>

                                                        <TableCell className={classes.tableCell}>
                                                            {singleDatumOrder.dispensed_to}
                                                        </TableCell>

                                                        <TableCell className={classes.tableCell}>
                                                            {nf.format(singleDatumOrder.quantity)} Litres
                                                        </TableCell>

                                                        <TableCell className={classes.tableCell}>
                                                            {nf.format(singleDatumOrder.dispensed_quantity)}{" "}
                                                            Litres
                                                        </TableCell>

                                                        <TableCell className={classes.tableCell}>
                                                            <Label
                                                                color={
                                                                    singleDatumOrder.status === 1
                                                                        ? orderStatusColors[2]
                                                                        : singleDatumOrder.status === 2
                                                                            ? orderStatusColors[3]
                                                                            : singleDatumOrder.status === 3
                                                                                ? orderStatusColors[6]
                                                                                : singleDatumOrder.status === 4
                                                                                    ? orderStatusColors[5]
                                                                                    : singleDatumOrder.status === 5
                                                                                        ? orderStatusColors[4]
                                                                                        : orderStatusColors[1]
                                                                }
                                                            >
                                                                {singleDatumOrder.status === 1
                                                                    ? "On Going"
                                                                    : singleDatumOrder.is_done_by_lmd === false &&
                                                                    singleDatumOrder.status === 2
                                                                        ? "Completed"
                                                                        : singleDatumOrder.is_done_by_lmd === false &&
                                                                        singleDatumOrder.status === 3
                                                                            ? "Cancelled"
                                                                            : singleDatumOrder.status === 4
                                                                                ? "Placed"
                                                                                : singleDatumOrder.status === 5
                                                                                    ? "Dispensing"
                                                                                    : singleDatumOrder.is_done_by_lmd === true &&
                                                                                    singleDatumOrder.status === 2
                                                                                        ? "Completed by LMD"
                                                                                        : singleDatumOrder.is_done_by_lmd === true &&
                                                                                        singleDatumOrder.status === 3
                                                                                            ? "Cancelled by lmd"
                                                                                            : singleDatumOrder.is_done_by_opt === true &&
                                                                                            singleDatumOrder.status === 2 ? "Completed by OPT" :
                                                                                            "Not Available"}
                                                            </Label>
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {singleDatumOrder.status === 2 ? (
                                                                "Order is Completed"
                                                            ) : singleDatumOrder.status === 3 ? (
                                                                "Order is Cancelled"
                                                            ) : (
                                                                <FormControl
                                                                    variant="outlined"
                                                                    className={classes.formControl}
                                                                >
                                                                    <InputLabel id="update-input-selector">
                                                                        Update Status
                                                                    </InputLabel>
                                                                    <Select
                                                                        style={{width: "130px"}}
                                                                        labelId="update-input-selector-label"
                                                                        id="update-input-selector-select"
                                                                        label="Update Status"
                                                                        onChange={event =>
                                                                            this.handleUpdateStatus(
                                                                                event,
                                                                                singleDatumOrder.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>None</em>
                                                                        </MenuItem>
                                                                        <MenuItem value={2}>Completed</MenuItem>
                                                                        <MenuItem value={3}>Cancelled</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        )}
                                    </Table>
                                ) : (
                                    <Table>
                                        {this.props?.isLoading ? (
                                            <TableCell colSpan={"18"}>
                                                <div className={classes.progressWrapper}>
                                                    <LinearProgress
                                                        fullWidth
                                                        className={classes.progress}
                                                    />
                                                </div>
                                            </TableCell>
                                        ) : null}
                                        <TableHead>
                                            <TableRow
                                                style={{
                                                    backdropFilter: "blur(5px)",
                                                    background: "rgb(255 255 255 / 36%)",
                                                    backgroundBlendMode: "exclusion",
                                                    boxShadow: "2px 3px 7px 0px rgb(0 0 0 / 37%)",
                                                    position: "sticky",
                                                    top: 0,
                                                    zIndex: 10
                                                }}
                                            >
                                                <TableCell align="center">Created at</TableCell>
                                                <TableCell align="center">Customer Name</TableCell>
                                                <TableCell align="center">Asset Name</TableCell>
                                                <TableCell align="center">City</TableCell>
                                                <TableCell align="center">State</TableCell>
                                                <TableCell align="center">Level Info </TableCell>
                                                <TableCell align="center">Datum Model</TableCell>
                                                <TableCell align="center">Asset Type</TableCell>
                                                <TableCell align="center">Sub-Asset Count</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {this.props.dataCount === 0 ? (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={"9"}>
                                                        <NoDatafound/>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                {datumOrdersData?.map(singleDatumOrder => (
                                                    <TableRow
                                                        className={classes.tableRow}
                                                        hover
                                                        key={singleDatumOrder.id}
                                                    >
                                                        <TableCell className={classes.tableCell}>
                                                            <div className={classes.tableCellInner}>
                                                                <Typography
                                                                    className={classes.nameText}
                                                                    variant="body1"
                                                                >
                                                                    {singleDatumOrder.created_at}
                                                                </Typography>
                                                            </div>
                                                        </TableCell>

                                                        <TableCell
                                                            className={classes.tableCell}
                                                            align="center"
                                                        >
                                                            {singleDatumOrder.customer_name}
                                                        </TableCell>

                                                        <TableCell
                                                            className={classes.tableCell}
                                                            align="center"
                                                        >
                                                            {singleDatumOrder.asset_name}
                                                        </TableCell>

                                                        <TableCell
                                                            className={classes.tableCell}
                                                            align="center"
                                                        >
                                                            {singleDatumOrder?.city ? (
                                                                singleDatumOrder?.city
                                                            ) : (
                                                                <Typography
                                                                    variant={"overline"}
                                                                    color={"error"}
                                                                >
                                                                    Not Available
                                                                </Typography>
                                                            )}
                                                        </TableCell>

                                                        <TableCell className={classes.tableCell}>
                                                            {singleDatumOrder.state === "" ? (
                                                                <Typography
                                                                    variant={"overline"}
                                                                    color={"error"}
                                                                >
                                                                    Not Available
                                                                </Typography>
                                                            ) : (
                                                                singleDatumOrder.state
                                                            )}
                                                        </TableCell>

                                                        <TableCell className={classes.tableCell}>
                                                            <FormControl className={classes.formControl}>
                                                                {singleDatumOrder.levels_info === null ? (
                                                                    "No data"
                                                                ) : (
                                                                    <div>
                                                                        <InputLabel
                                                                            id="levels_info"
                                                                            style={{
                                                                                color:
                                                                                    singleDatumOrder.levels_info.lvl1 &&
                                                                                    singleDatumOrder.levels_info.lvl2 < 0
                                                                                        ? "red"
                                                                                        : "black"
                                                                            }}
                                                                        >
                                                                            Levels
                                                                        </InputLabel>
                                                                        <Select
                                                                            labelId="levels_info"
                                                                            id="levels_info"
                                                                            style={{width: "65px"}}
                                                                        >
                                                                            <MenuItem value={10}>
                                                                                Level 1:{" "}
                                                                                {singleDatumOrder?.levels_info?.lvl1}
                                                                            </MenuItem>
                                                                            <MenuItem value={20}>
                                                                                Level 2:{" "}
                                                                                {singleDatumOrder?.levels_info?.lvl2}
                                                                            </MenuItem>
                                                                            <MenuItem value={30}>
                                                                                Level 3:{" "}
                                                                                {singleDatumOrder?.levels_info?.lvl3}
                                                                            </MenuItem>
                                                                        </Select>
                                                                    </div>
                                                                )}
                                                            </FormControl>
                                                        </TableCell>

                                                        <TableCell
                                                            className={classes.tableCell}
                                                            align="center"
                                                        >
                                                            {singleDatumOrder.datum_models}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}
                                                            align="center"
                                                        >
                                                            {asstType[singleDatumOrder.asset_type]}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}
                                                            align="center"
                                                        >
                                                            {singleDatumOrder.subasset_count}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        )}
                                    </Table>
                                )}
                            </TableContainer>

                            <div>
                                {this.state.updatedStatus === 3 ? (
                                    <Dialog
                                        fullWidth
                                        open={this.state.dialog_open}
                                        onClose={this.handleClose.bind(this)}
                                        aria-labelledby="simple-dialog-title"
                                    >
                                        {/*<DialogTitle style={{background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'}} id="simple-dialog-title">*/}
                                        <DialogTitle
                                            style={{
                                                background:
                                                    "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                            }}
                                            id="simple-dialog-title"
                                        >
                                            <div style={{margin: "15px 0px"}}>
                                                <Typography style={{color: "#fff"}} variant="h4">
                                                    Change the order status?
                                                </Typography>
                                            </div>
                                        </DialogTitle>
                                        <DialogContent>
                                            <div style={{margin: "10px 0px"}}>
                                                <Typography variant={"subtitle1"}>
                                                    Are you sure you want to Cancel this Order ?
                                                </Typography>
                                            </div>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                variant="text"
                                                color="default"
                                                onClick={this.handleClose.bind(this)}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                onClick={e => this.handleChangeStatus(e)}
                                                style={{
                                                    background:
                                                        "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)",
                                                    color: "#fff"
                                                }}
                                            >
                                                Update
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                ) : (
                                    <Dialog
                                        fullWidth
                                        open={this.state.dialog_open}
                                        onClose={this.handleClose.bind(this)}
                                        aria-labelledby="simple-dialog-title"
                                    >
                                        {/*<DialogTitle style={{background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'}} id="simple-dialog-title">*/}
                                        <DialogTitle
                                            style={{
                                                background:
                                                    "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)"
                                            }}
                                            id="simple-dialog-title"
                                        >
                                            <div style={{margin: "15px 0px"}}>
                                                <Typography style={{color: "#fff"}} variant="h4">
                                                    Change the order status?
                                                </Typography>
                                                <Typography
                                                    style={{color: "#fff"}}
                                                    variant={"subtitle1"}
                                                >
                                                    Are you sure you want to edit the given details ?
                                                </Typography>
                                            </div>
                                        </DialogTitle>
                                        <DialogContent>
                                            <div style={{margin: "10px 0px"}}>
                                                <TextField
                                                    label="Order Quantity"
                                                    id="outlined-size-small"
                                                    variant="outlined"
                                                    onChange={e => this.handleFieldChange(e.target.value)}
                                                    size="medium"
                                                    fullWidth
                                                    type={"number"}
                                                    required
                                                />
                                            </div>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                variant="text"
                                                color="default"
                                                onClick={this.handleClose.bind(this)}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                onClick={e => this.handleChangeStatus(e)}
                                                style={{
                                                    background:
                                                        "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)",
                                                    color: "#fff"
                                                }}
                                            >
                                                Update
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                )}
                            </div>
                        </PerfectScrollbar>
                        <div>
                            {this.props.tabClick === 0 ? (
                                <TablePagination
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    component="div"
                                    count={this.props.dataCount}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    page={this.props.page}
                                    rowsPerPage={this.props.rowsPerPage}
                                    rowsPerPageOptions={[10, 20, 50, 100, 200, 250, 300]}
                                />
                            ) : (
                                <TablePagination
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page"
                                    }}
                                    component="div"
                                    count={this.props.dataCount}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page"
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    page={this.props.page}
                                    rowsPerPage={this.props.rowsPerPage}
                                    rowsPerPageOptions={[10, 20, 50]}
                                />
                            )}
                        </div>
                    </PortletContent>
                </Portlet>
            );
    }
}

export default withStyles(styles)(UsersTable);
