const keys = {
  /***Live***/
  client_secret: 'ph0kAUrn1W3oeG8DUaK5nDtXWIbiRBgOT6Mi8yj996SdEEttIBBYvdWAv7g21t4EtT6iHXUXN9s0jhlfD1gu2wTcBMtNNw7Me1lAxt1SeDpAoj9kQaakPznJGJ1sXm0t',
  client_id: 'I9M9t7xqqIpaJWh1jXiiisBOeZ7ZgmtWygIHwYjI',

  /*** Staging ***/
  // client_secret:"4q3q9V7mMV63KT0ElEVUQTsnPx4fayMNPkUz0cD8MMp66JTRi5XwHJ1UPlq2FdYKyntqH4IIBmKdhbaYJkFviUr2d50VqBwEbqhheIuBVPQv34aDelwV0mktZYRTSvHk",
  // client_id: "ga2vWVuEyMIdgC8iwLHSbfjQMT5kpbb504Hf8TvL"

  /*********** new staging ***********/

  // client_secret:"ph0kAUrn1W3oeG8DUaK5nDtXWIbiRBgOT6Mi8yj996SdEEttIBBYvdWAv7g21t4EtT6iHXUXN9s0jhlfD1gu2wTcBMtNNw7Me1lAxt1SeDpAoj9kQaakPznJGJ1sXm0t",
  // client_id: "I9M9t7xqqIpaJWh1jXiiisBOeZ7ZgmtWygIHwYjI"

};
export default keys;